import React, { useEffect, useState } from "react";
import { Modal, Popconfirm, Table, Tooltip } from "antd";
import { AiFillEye } from "react-icons/ai";
import {
  TbCheck,
  TbCross,
  TbLock,
  TbLockOff,
  TbPlayerEject,
} from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { FullScreenLoader } from "../../../components/loaders/loading";
import { toast } from "react-toastify";
import moment from "moment";
import useAuthUser from "../../../hooks/useAuthUser";
import { AccountStatus, UserRoles } from "../../../utils/constant";
import { getAllUsers, updateUser } from "../../../features/admin/adminSlice";
import UserProfile from "../../../components/user/userProfile";
import {
  MdCancel,
  MdCancelPresentation,
  MdCancelScheduleSend,
} from "react-icons/md";
import { FcCancel } from "react-icons/fc";
import { setError } from "../../../features/ui/uiSlice";
import { getAllReports } from "../../../features/report/reportSlice";
import ReportView from "../../../components/report/reportView";
import { useTranslation } from "react-i18next";

export default function AllReports() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let { authUser } = useAuthUser();

  useEffect(() => {
    if (authUser?._id) {
      let query = "";
      dispatch(getAllReports(query));
    }
  }, [authUser]);

  const ReportsSelector = useSelector((state) => state.report);
  const { Reports, message, isError, isLoading } = ReportsSelector;

  useEffect(() => {
    if (isError && message) {
      dispatch(setError(message || ""));
    }
  }, [isError, message]);

  const columns = [
    {
      title: t("Product Name"),
      dataIndex: "productName",
      render: (_, record) => <div>{record?.productName}</div>,
    },
    {
      title: t("Product Code (SKU)"),
      dataIndex: "productCode",
      render: (_, record) => <div>{record?.productCode}</div>,
    },
    {
      title: t("Product Brand"),
      dataIndex: "brandName",
      render: (_, record) => <div>{record?.brandName}</div>,
    },
    {
      title: t("Product Category"),
      dataIndex: "productCategory",
      render: (_, record) => <div>{record?.productCategory}</div>,
    },
    {
      title: t("Purchase Price"),
      dataIndex: "purchasePrice",
      render: (_, record) => <div>{record?.purchasePrice}</div>,
    },
    {
      title: t("Purchase Date"),
      dataIndex: "purchaseDate",
      render: (_, record) => (
        <div>{moment(record?.purchaseDate).format("DD-MM-YY")}</div>
      ),
    },
    {
      title: t("Action"),
      dataIndex: "action",
      render: (_, record) => (
        <div className="flex items-center gap-2">
          <Tooltip
            className="!text-textPrimary fs-5 cursor-pointer"
            title="View"
            onClick={() => showModal(record)}
          >
            <AiFillEye />
          </Tooltip>
        </div>
      ),
    },
  ];

  const dataSource = Reports?.allReports?.map((item) => ({
    ...item,
    key: item._id,
  }));

  /* Modal */
  const [isOpen, setIsOpen] = useState("");
  const [reportView, setReportView] = useState("");

  const showModal = (item) => {
    setIsOpen(true);
    setReportView(item);
  };

  const handleClose = () => {
    setIsOpen(false);
    setReportView("");
  };

  return (
    <div>
      <FullScreenLoader loading={isLoading} />
      <Modal
        open={isOpen}
        onOk={handleClose}
        onCancel={handleClose}
        width={"1000px"}
        footer=""
      >
        {reportView && <ReportView item={reportView} />}
      </Modal>
      <h3 className="mb-4 title">{t("All Counterfit Products")}</h3>
      <div className="overflow-x-auto pb-4">
        <Table columns={columns} dataSource={dataSource} />
      </div>
    </div>
  );
}
