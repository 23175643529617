import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { registerCompany } from "../../features/auth/authSlice";
import { toast } from "react-toastify";
import FileUpload from "../ui/fileUpload";
import { fadeVariants, handleLogout } from "../../utils/helper";
import { motion } from "framer-motion";
import { BtnLoader } from "../loaders/loading";
import { setError } from "../../features/ui/uiSlice";
import { Modal } from "antd";
import { MdVerified } from "react-icons/md";
import CustomButton from "../ui/customButton";
import { useTranslation } from "react-i18next";

export default function CompanyAttachments({ handleBack }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let schema = yup.object().shape({
    commercialRegistration: yup
      .object()
      .required(t("Commercial Registration is Required")),
    vatCertificate: yup.object().required(t("VAT Certificate is Required")),
  });

  const authState = useSelector((state) => state);

  const { company, register_company, isError, isSuccess, isLoading, message } =
    authState.auth;

  // useEffect(() => {
  //   if (!company) {
  //     handleBack();
  //   }
  // }, [company]);

  const formik = useFormik({
    initialValues: {
      commercialRegistration: "",
      vatCertificate: "",
      tradeLicense: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (company) {
        dispatch(
          registerCompany({
            ...company,
            attachments: [
              {
                commercialRegistration: values.commercialRegistration,
                vatCertificate: values.vatCertificate,
                ...(values.tradeLicense && {
                  tradeLicense: values.tradeLicense,
                }),
              },
            ],
          })
        );
      }
    },
  });

  useEffect(() => {
    if (isSuccess && register_company) {
      setSuccessModal(true);
    }
  }, [register_company, isError, isSuccess]);

  useEffect(() => {
    if (isError && message) {
      dispatch(setError(message || ""));
    }
  }, [isError, message]);

  /* Modal */
  const [successModal, setSuccessModal] = useState(false);

  return (
    <motion.div
      variants={fadeVariants}
      initial="initial"
      animate="animate"
      transition="transition"
      exit="exit"
    >
      <Modal
        open={successModal}
        keyboard={false}
        maskClosable={false}
        footer=""
        centered
      >
        <div className="p-6">
          <div className="flex flex-column gap-2 items-center justify-center">
            <MdVerified size={82} color="#09cfab" />
            <h3 className="text-center title">
              {t("Your Account Has Been Created")}
            </h3>
            <p className="text-center text-xl">
              {t(
                "A verification email has been sent to your inbox. Please verify"
              )}
            </p>
            <CustomButton onClick={handleLogout}>
              {t("I’ve Verified My Email")}
            </CustomButton>
          </div>
        </div>
      </Modal>
      <form action="" onSubmit={formik.handleSubmit}>
        <div className="my-3 mt-4">
          <FileUpload
            label={t("Commercial Registartion (PDF Only)")}
            setFileName={(file) =>
              formik.setFieldValue("commercialRegistration", file)
            }
            accept={"application/pdf"}
            isFile={true}
            required={true}
          />
          <div className="error mt-2">
            {formik.touched.commercialRegistration &&
              formik.errors.commercialRegistration}
          </div>
        </div>

        <div className="my-3">
          <FileUpload
            label={t("VAT Certificate (PDF Only)")}
            setFileName={(file) => formik.setFieldValue("vatCertificate", file)}
            accept={"application/pdf"}
            isFile={true}
            required={true}
          />
          <div className="error mt-2">
            {formik.touched.vatCertificate && formik.errors.vatCertificate}
          </div>
        </div>

        <div className="my-3">
          <FileUpload
            label={t("Trade License (PDF Only)")}
            setFileName={(file) => formik.setFieldValue("tradeLicense", file)}
            accept={"application/pdf"}
            isFile={true}
          />
          <div className="error mt-2">
            {formik.touched.tradeLicense && formik.errors.tradeLicense}
          </div>
        </div>

        <div className="flex items-center gap-2 mt-4">
          <button
            className="border-0 px-3 py-2 text-white fw-bold w-100 text-center text-decoration-none fs-5
          bg-btnSecondary hover:bg-opacity-80 transition-colors rounded-2 disabled:!bg-textSecondary disabled:cursor-wait"
            type="button"
            onClick={() => handleBack()}
          >
            {t("Back")}
          </button>

          <button
            className="border-0 px-3 py-2 text-white fw-bold w-100 text-center text-decoration-none fs-5
          bg-btnPrimary hover:bg-opacity-80 transition-colors rounded-2 disabled:!bg-textSecondary disabled:cursor-wait"
            type="submit"
            disabled={isLoading}
          >
            {t("Register")}
            <BtnLoader loading={isLoading} />
          </button>
        </div>
      </form>
    </motion.div>
  );
}
