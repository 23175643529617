import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enLocale from "./assets/locales/en.json";
import arLocale from "./assets/locales/ar.json";

const getLanguage = () => {
  return localStorage.getItem("lang") || "ar";
};

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enLocale,
    },
    ar: {
      translation: arLocale,
    },
  },
  lng: getLanguage(),
  fallbackLng: "ar",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
