import React from "react";
import { BiError } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../features/ui/uiSlice";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

export default function ErrorModal() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { error = "" } = useSelector((state) => state.ui);

  const handleClose = () => {
    dispatch(setError(""));
  };

  return (
    <Modal
      open={error}
      onOk={handleClose}
      onCancel={handleClose}
      keyboard={false}
      maskClosable={false}
      footer=""
      centered
    >
      <div className="p-6">
        <div className="flex flex-col items-center gap-6 text-center">
          <BiError size={72} color="#dc3545" />
          <div>
            <p className="text-2xl font-semibold text-gray-800 mb-2">
              {t("Error")}
            </p>
            <p className="text-gray-600 text-lg">{error || ""}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
