import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Image, Upload } from "antd";
import { base_url } from "../../utils/baseUrl";
import { getFileFromServer } from "../../utils/helper";
import { useTranslation } from "react-i18next";

const FileUpload = ({
  label,
  setFileName,
  accept,
  isFile = false,
  required = false,
}) => {
  const { t } = useTranslation();

  const [previewImage, setPreviewImage] = useState("");

  const props = {
    name: "singleFile",
    action: `${base_url}common/upload-single`,
    accept,
    maxCount: 1,
    onChange(info) {
      if (info.file.status === "uploading") {
        return;
      }
      if (info.file.status === "done") {
        const { responseData } = info.file.response;
        setFileName(responseData.file);
        setPreviewImage(getFileFromServer(responseData.file?.file));
      }
    },
  };

  return (
    <div className="flex flex-column gap-2">
      <label>
        {label}
        {required && <span className="text-danger"> *</span>}
      </label>
      <Upload {...props}>
        <button
          type="button"
          className="!bg-btnSecondary hover:!bg-opacity-80 transition-colors rounded-2 disabled:!bg-secondary disabled:cursor-wait text-textClr font-semibold rounded-2 px-4 py-2 flex items-center gap-2 cursor-pointer"
        >
          <UploadOutlined />
          {t("Select File")}
        </button>
        {/* <Button icon={<UploadOutlined />}>Select File</Button> */}
      </Upload>
      {previewImage && !isFile && (
        <Image
          wrapperStyle={{ display: "block" }}
          src={previewImage}
          height={200}
          width={300}
        />
      )}
    </div>
  );
};

export default FileUpload;
