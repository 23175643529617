import _countries from "../utils/all-countries.json";
// import _gpc_classification from "../utils/gps_product_classification.json";

export const PrintSizes = [
  {
    id: 1,
    name: "1 x 1cm",
    height: "80",
    width: "80",
  },
  {
    id: 2,
    name: "3 x 3cm",
    height: "120",
    width: "120",
  },
  {
    id: 3,
    name: "5 x 5cm",
    height: "160",
    width: "160",
  },
];

export const UserRoles = [
  {
    id: 1,
    name: "Merchant",
    link: "/user",
  },
  {
    id: 2,
    name: "Customer",
    link: "/user",
  },
  // {
  //   id: 3,
  //   name: "Admin",
  //   link: "/admin",
  // },
];

export const AccountStatus = {
  UNBLOCKED: "Active",
  REJECTED: "Rejected",
  BLOCKED: "Blocked",
  PENDING: "Pending",
};

export const getCountries = () => {
  return Object.keys(_countries) || [];
};

export const getCities = (country) => {
  return _countries[country] || {};
};

export const CountriesOptions = Object.keys(_countries) || [];

// export const ProductClassificationOptions = [
//   ...new Set(
//     _gpc_classification["Schema"]
//       .flatMap((item) => item.Childs)
//       .flatMap((child) => child.Childs)
//       .map((child) => child.Title)
//   ),
// ].sort();

export const SaudiCities = [
  { Id: 1, Code: "RUH", NameEn: "Riyadh", NameAr: "الرياض", "hc-key": "sa-ri" },
  { Id: 2, Code: "JED", NameEn: "Jeddah", NameAr: "جدة", "hc-key": "sa-mk" },
  {
    Id: 3,
    Code: "MED",
    NameEn: "Al Madinah",
    NameAr: "المدينة المنورة",
    "hc-key": "sa-md",
  },
  { Id: 4, Code: "DMM", NameEn: "Dammam", NameAr: "الدمام", "hc-key": "sa-sh" },
  { Id: 5, Code: "TUU", NameEn: "Tabuk", NameAr: "تبوك", "hc-key": "sa-tb" },
  {
    Id: 6,
    Code: "AQI",
    NameEn: "Qaisumah",
    NameAr: "قيسومة",
    "hc-key": "sa-qs",
  },
  { Id: 7, Code: "AJF", NameEn: "Al-Jouf", NameAr: "الجوف", "hc-key": "sa-jf" },
  {
    Id: 8,
    Code: "ELQ",
    NameEn: "Al-Qassim",
    NameAr: "القصيم",
    "hc-key": "sa-qs",
  },
  { Id: 9, Code: "YNB", NameEn: "Yanbu", NameAr: "ينبع", "hc-key": "sa-md" },
  { Id: 10, Code: "ULH", NameEn: "Al-Ula", NameAr: "العلا", "hc-key": "sa-md" },
  { Id: 11, Code: "URY", NameEn: "Urayyiq", NameAr: "عريع", "hc-key": "sa-ri" },
  { Id: 12, Code: "GIZ", NameEn: "Jizan", NameAr: "جيزان", "hc-key": "sa-jz" },
  { Id: 13, Code: "AHB", NameEn: "Abha", NameAr: "أبها", "hc-key": "sa-as" },
  { Id: 14, Code: "BHH", NameEn: "Bisha", NameAr: "بيشة", "hc-key": "sa-ba" },
  { Id: 15, Code: "TIF", NameEn: "Ta'if", NameAr: "الطائف", "hc-key": "sa-mk" },
  { Id: 16, Code: "HOF", NameEn: "Hofuf", NameAr: "الهفوف", "hc-key": "sa-sh" },
  {
    Id: 17,
    Code: "AJF",
    NameEn: "Al-Jouf",
    NameAr: "الجوف",
    "hc-key": "sa-jf",
  },
  { Id: 18, Code: "URM", NameEn: "Urm", NameAr: "أرم", "hc-key": "sa-ri" },
];
