import Select from "react-select";
import "../../index.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CustomSelect = (props) => {
  const {
    children,
    label,
    s_id,
    name,
    val,
    onChng,
    onBlr,
    placeholder,
    options = [],
    required = false,
  } = props;

  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-y-1 mt-3">
      <label>
        {label}
        {required && <span className="text-danger"> *</span>}
      </label>
      <div className={`custom-select-wrapper`}>
        <Select
          options={options}
          placeholder={placeholder || t("Select...")}
          classNamePrefix={"cm-select"}
          id={s_id}
          name={name}
          value={val}
          onChange={onChng}
          {...props}
        >
          {children}
        </Select>
      </div>
    </div>
  );
};

export default CustomSelect;
